<template>
    <v-container style="margin-top: 75px;">
        <v-card>
            <v-data-table :headers="headers" :items="banners" :server-items-length="totalItems" :options.sync="options"
                :loading="loading" :footer-props="footerProps" class="elevation-1">

                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>轮播图列表</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click="addBanner()">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>添加轮播图</span>
                        </v-tooltip>
                    </v-toolbar>
                </template>

                <template v-slot:item.imageUrl="{ item }">
                    <v-avatar>
                        <v-icon @click="showImage(item)">mdi-eye</v-icon>
                    </v-avatar>
                </template>

                <template v-slot:item.status="{ item }">
                    <!-- 根据 item.status 的值显示“已启用”或“未启用” -->
                    <span>{{ item.status === 1 ? '已启用' : '未启用' }}</span>
                </template>

                <template v-slot:item.action="{ item }">
                    <!-- 编辑按钮 -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon small v-on="on" @click="editItemMethod(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>编辑</span> <!-- 编辑按钮的提示信息 -->
                    </v-tooltip>

                    <!-- 禁用按钮 -->
                    <v-tooltip bottom>

                        <template v-slot:activator="{ on }">
                            <v-btn icon small v-on="on" @click="disableItem(item)">
                                <v-icon>mdi-close-octagon</v-icon>
                            </v-btn>
                        </template>
                        <span>删除</span> <!-- 禁用按钮的提示信息 -->
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="addDialog" max-width="500px">
            <v-card>
                <!-- 添加框标题 -->
                <v-card-title class="headline">添加轮播图</v-card-title>
                <!-- 添加框表单内容 -->
                <v-card-text>
                    <!-- 标题输入框 -->
                    <v-text-field label="标题-长度10" v-model="title" maxlength="10"></v-text-field>
                    <!-- 简介输入框 -->
                    <v-textarea label="简介-长度80" v-model="description" maxlength="80"></v-textarea>
                    <!-- 图片上传组件 -->
                    <v-file-input label="图片上传" v-model="imageFile" accept="image/*" @change="onFileSelected('add')"
                        required></v-file-input>
                    <!-- 顺序字段输入框 -->
                    <v-text-field label="顺序" v-model="order" type="number" min="0" required></v-text-field>
                </v-card-text>
                <!-- 添加框底部操作按钮 -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- 取消按钮 -->
                    <v-btn color="blue darken-1" text @click="addDialog = false">取消</v-btn>
                    <!-- 提交按钮，点击时调用添加项目的逻辑 -->
                    <v-btn color="primary" dark @click="addProject">添加</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editDialog" max-width="500px">
            <v-card>
                <!-- 添加框标题 -->
                <v-card-title class="headline">修改轮播图</v-card-title>
                <!-- 添加框表单内容 -->
                <v-card-text>
                    <!-- 标题输入框 -->
                    <v-text-field label="标题" v-model="editItem.title" maxlength="10"></v-text-field>
                    <!-- 简介输入框 -->
                    <v-textarea label="简介" v-model="editItem.smartTitle" maxlength="80"></v-textarea>
                    <!-- 图片上传组件 -->
                    <v-file-input label="图片上传" v-model="imageFile" accept="image/*" @change="onFileSelected('edit')"
                        required></v-file-input>
                    <!-- 顺序字段输入框 -->
                    <v-text-field label="顺序" v-model="editItem.ownOrder" type="number" min="0" required></v-text-field>
                    <v-select label="是否启用" v-model="editItem.status" :items="statusItems"></v-select>
                </v-card-text>
                <!-- 添加框底部操作按钮 -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- 取消按钮 -->
                    <v-btn color="blue darken-1" text @click="editDialog = false">取消</v-btn>
                    <!-- 提交按钮，点击时调用添加项目的逻辑 -->
                    <v-btn color="primary" dark @click="editProject()">修改</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="uploadDialog" persistent width="300">
            <v-card>
                <v-card-title class="headline">请求中...</v-card-title>
                <v-card-text>
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showImageDialog" max-width="500px">
            <v-card>
                <v-img :src="showImageUrl" @click="showImageDialog = false"></v-img>

                <v-card-title>图片展示</v-card-title>
                <v-card-subtitle>
                    当前轮播图图片
                    <v-btn icon class="float-right" @click="downloadImage">
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                </v-card-subtitle>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import api from "../../api/api";
const pre = 'http://120.26.117.241:8055/msn/';
import axios from 'axios';
const _ = require('lodash');
export default {
    data() {
        return {
            showImageUrl: '',
            showImageDialog: false,
            uploadDialog: false,
            editDialog: false,
            editItem: {
                title: '',
                smartTitle: '',
                imageFile: null,
                order: '',

            },
            statusItems: [
                { text: '启用', value: 1 },
                { text: '禁用', value: 0 },
            ],
            title: '',
            description: '',
            imageFile: null,
            imageUrl: null,
            editImageUrl: null,
            order: 0, // 初始值为0，非负数
            addDialog: false,
            headers: [
                { text: '标题', align: 'title', sortable: false, value: 'title', class: 'truncate' },
                { text: '简介', value: 'smartTitle', sortable: false, class: 'truncate' },
                { text: '关联图片', value: 'imageUrl', sortable: false },
                { text: '排序', value: 'ownOrder', sortable: false, width: '100px' },
                { text: '启用状态', value: 'status', sortable: false, width: '100px' },
                { text: '创建时间', value: 'createTime', sortable: false, width: '200px' },
                { text: '更新时间', value: 'lastUpdateTime', sortable: false, width: '200px' },
                { text: '操作', value: 'action', sortable: false, width: '100px' },
            ],
            banners: [],
            options: {
                page: 1,
                itemsPerPage: 5,
            },
            loading: false,
            totalItems: 0,
            footerProps: {
                itemsPerPageOptions: [5, 10, 15],
                showFirstLastPage: true,
                showCurrentPage: true,
                showTotalItems: true
            }
        };
    },
    watch: {
        options: {
            handler() {
                this.fetchItems();
            },
            deep: true,
        },
        showImageDialog: {
            handler() {
                if (this.showImageDialog == false) {
                    this.showImageUrl = '';
                }
            }
        }
    },
    created() {
        this.fetchItems();
    },
    methods: {
        async downloadImage() {
            try {
                const response = await axios({
                    url: this.showImageUrl,
                    method: 'GET',
                    responseType: 'blob' // 重要：设置响应类型为 blob
                });

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'downloaded-image.jpg'); // 下载文件名
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Error downloading image', error);
            }
        },
        showImage(item) {
            this.showImageUrl = item.imageUrl;
            this.showImageDialog = true;
            // return item.imageFile;
        },
        onFileSelected(type) {
            this.uploadDialog = true;
            api.postFile('/center/upload', this.imageFile).then(response => {
                // 上传成功的处理
                if (response.status == 200) {
                    console.log(response.data.data);
                    if (type == 'add') {
                        this.imageUrl = pre + response.data.data;
                    } else if (type == 'edit') {
                        this.editImageUrl = pre + response.data.data;
                    }
                }
                this.uploadDialog = false;
                // this.imageFile = null;
            }).catch(error => {
                // 上传失败的处理
                console.error('上传失败', error);
                this.uploadDialog = false;
                // this.imageFile = null;
            });
        },
        addBanner() {
            this.addDialog = true;
        },
        addProject() {
            api.get('/manager/addBanner?title=' + this.title + '&&smartTitle=' + this.description
                + '&&url=' + this.imageUrl + '&&order=' + this.order
            ).then(response => {
                if (response.status == 200) {
                    confirm('添加成功');
                }
                this.addDialog = false;
                this.imageUrl = null;
                this.fetchItems();
            }).catch(error => {
                console.log(error);
                this.imageUrl = null;
            })
        },
        fetchItems() {
            this.loading = true;
            api.get(`manager/getBanners?pageNum=${this.options.page}&pageSize=${this.options.itemsPerPage}`).then(response => {
                this.loading = false;
                if (response.status === 200) {
                    this.totalItems = response.data.data.total;
                    this.banners = response.data.data.records;
                    for (const banner of this.banners) {
                        banner.createTime = this.formatDate(banner.createTime);
                        banner.lastUpdateTime = this.formatDate(banner.lastUpdateTime);
                    }
                    console.log(this.banner)
                }
            }).catch(() => {
                this.loading = false;
            });
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() 返回的月份是从 0 开始的
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        editItemMethod(item) {
            this.editDialog = true;
            this.editItem = _.cloneDeep(item);
            console.log(item);
            console.log(this.editItem)
        },
        editProject() {
            let url = this.editItem.imageUrl;
            if (this.editImageUrl != null && this.editImageUrl != '') {
                url = this.editImageUrl;
            }
            api.get('/manager/updateBanner?title=' + this.editItem.title + '&&smartTitle=' + this.editItem.smartTitle
                + '&&url=' + url + '&&order=' + this.editItem.ownOrder + "&&id=" + this.editItem.id + '&&status=' + this.editItem.status
            ).then(response => {
                if (response.status == 200) {
                    confirm('修改成功');
                }
                this.editDialog = false;
                this.editImageUrl = null;
                this.fetchItems();
            }).catch(error => {
                console.log(error);
                this.editImageUrl = null;
            });
        },
        disableItem(item) {
            api.get('manager/delBanner?id=' + item.id).then(response => {
                if (response.status == 200) {
                    confirm('删除成功');
                    this.fetchItems();
                }
            });
            // this.fetchItems();
        }
    },
};
</script>

<style scoped>
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.status {
    width: 50px;
}
</style>